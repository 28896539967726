import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
import { InputFieldContainer, AddButton, FormWrapper, AttendeesContainer, StyledModal, CustomTitle, ArrowContainer, PaginationCircle } from "./styles";
import Image from "../Image";
import { connect } from 'react-redux';
import _ from 'lodash';
import { UpdateAttendeesListV2, SearchAttendeesForEvent } from "../../redux/actions";
import Waiting from "../Waiting";
import { NoGuests, ContentWrapper } from "../RemoveAttendeesModal/styles";
import { withTranslation } from 'react-i18next';
class AddAttendeesModal extends Component {
  constructor() {
    super();
    this.state = {
      userName: '',
      user: 20,
      minLimit: 1,
      maxLimit: 1,
    }
  }
  componentDidMount() {
    const { SearchAttendeesForEvent, eventId } = this.props;
    let obj = { "event_id": eventId, name: "" };
    SearchAttendeesForEvent(obj);
  }

  onBlur = (e) => {
    this.setState({
      userName: e.target.value
    })
  };
  onSubmit = (e) => {
    // console.log("testing",e);
    // e.preventDefault();
    const { eventId, SearchAttendeesForEvent } = this.props;
    // const { userName } = this.state;
    let obj = {
      "event_id": eventId,
      "name": e.target.value
    };
    // SearchAttendeesForEvent(obj);
    // this.setState({
    //   userName: '',
    // })
    this.setState({
      userName: e.target.value,
    }, () => {
      SearchAttendeesForEvent(obj);
    })


  };
  addAttendees = (uid, ) => {
    const { eventId, addAttendee } = this.props;
    let obj = {};
    obj['event_id'] = eventId;
    obj['user_id'] = uid;
    obj['action_role'] = 'add_event';
    addAttendee(obj, eventId);
  };
  renderUser = (Attendees) => {
    const { user } = this.state;
    let AttendeesShow = Attendees.slice(0, user);
    const { t } = this.props;
    return (
      AttendeesShow.map((attendee, index) => (
        <ContentWrapper key={index} width={index === 0 ? 1 : 0} style={{border:"1px solid #6399af",borderBottom:index !== 0 && "none"}}>
          <div>
            <div className="image">
              <Image image={attendee.profile_image} alt={attendee.name} />
            </div>
            <div className="attendee">
              <span>{t(attendee.name)}</span>
              <p>{t(attendee.department)}</p>
            </div>
          </div>
          <AddButton onClick={() => this.addAttendees(attendee.uid)}>{t("Add")}</AddButton>
        </ContentWrapper>
      )))
  };

  leftArrow = () => {
    const { user, minLimit, maxLimit } = this.state;
    if (user > 20 && minLimit >= 1) {
      this.setState({
        user: user - 20,
        minLimit: minLimit - 1,
        maxLimit: maxLimit - 1
      })
    }
  };

  rightArrow = (users) => {
    const { user, minLimit, maxLimit } = this.state;
    if (users.length > user) {
      this.setState({
        user: user + 20,
        maxLimit: maxLimit + 1
      })
    }
    if ((users.length / 20) > minLimit) {
      this.setState({
        minLimit: minLimit + 1
      })
    }
  };

  render() {
    const { onHide, showModal, searchedAttendees, t } = this.props;
    const { userName, user, minLimit, maxLimit} = this.state;
    if (_.isNull(searchedAttendees) || _.isUndefined(searchedAttendees)) {
      return <Waiting />;
    }
    let usersCount = Math.round(searchedAttendees.length / 20);
    return (
      <StyledModal
        show={showModal}
        onHide={() => onHide()}
        dialogClassName="modal-90w"
      >
        <Modal.Header style={{background:"#005c87"}}>
          <CustomTitle>
            <div>
              <span>{t("Add Attendees")}</span>
              <span className="closeImage"><img src='/public/images/new_close_icon.png' onClick={() => onHide()} /></span>
            </div>
          </CustomTitle>
        </Modal.Header>
        <Modal.Body>
          <FormWrapper >
            <InputFieldContainer color="#005c87" bgColor="#faf9f4" pColor="#649bb3">
              <input
                type='text'
                name='attendees'
                placeholder='Search Buddies'
                // onChange={(e) => this.onBlur(e)}
                min={18}
                max={100}
                value={userName}
                onChange={(e) => this.onSubmit(e)}
              />
              <div className="image">
                <img src='/public/images/NewDashboardV2/searchIcon.png' /*onClick={(e) => this.onSubmit(e)}*/ />
              </div>
            </InputFieldContainer>
          </FormWrapper>
          <AttendeesContainer margin={searchedAttendees.length > 20 ? '0px' : '25px'}>
            {searchedAttendees.length > 0 ? this.renderUser(searchedAttendees) : <NoGuests style={{color:"#005c87"}}>{t("No Attendees Found")}</NoGuests>}
          </AttendeesContainer>
          {searchedAttendees.length>20&&<ArrowContainer width={user>20?'50px':'0px'}>
            <div>{user > 20 ? <img src='/public/images/CompanyDashBoardV2/NewIcon/leftcrossIcon.png' onClick={() => this.leftArrow(searchedAttendees)} /> : ""}
            </div>
            {<div>
              {maxLimit == usersCount ? <PaginationCircle active={maxLimit >= usersCount ? 0 : 1}>
                <div>{minLimit - 1}
                </div>
              </PaginationCircle> :
                <PaginationCircle active={maxLimit >= usersCount ? 0 : 1}>
                  <div>{minLimit}
                  </div>
                </PaginationCircle>}
              <PaginationCircle active={maxLimit >= usersCount ? 1 : 0}>
                <div>{usersCount}
                </div>
              </PaginationCircle>
            </div>}
            {maxLimit == usersCount ? '' : <div><img src='/public/images/CompanyDashBoardV2/NewIcon/rightcrossIcon.png' onClick={() => this.rightArrow(searchedAttendees)} />
            </div>}
          </ArrowContainer>}
        </Modal.Body>
      </StyledModal>

    );
  }
}
AddAttendeesModal.propTypes = {
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string,
  subContent: PropTypes.string,
  eventId: PropTypes.number,
  Attendees: PropTypes.array,
  SearchAttendeesForEvent: PropTypes.func,
  addAttendee: PropTypes.func,
  searchedAttendees: PropTypes.array,
  t: PropTypes.func
};
const mapStateToProps = (state) => ({
  searchedAttendees: state.events.searchedAttendees
});
const mapDispatchToProps = (dispatch) => ({
  SearchAttendeesForEvent: (data) => dispatch(SearchAttendeesForEvent(data)),
  addAttendee: (data, eventId) => dispatch(UpdateAttendeesListV2(data, eventId))
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddAttendeesModal));