/* eslint-disable camelcase */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {Layout, FormContainer} from '../EditWellnessJourney/styles';
import {
  FieldTitle,
  ImageContainer,
  ImageContentContainer,
  ImageInput,
  PageTitle,
  RemovePhotoButton, StyledInput, TitleContainer
} from '../CreateEvent/styles';
import { createJourneyCategory } from '../../redux/actions';
import {imageErrorMessage} from "../../utils/constants";
import {checkImage, getOrientation, resetOrientation} from "../../utils/methods";
import {toast} from "react-toastify";
import {withTranslation} from 'react-i18next';
import CommonButton from '../common/CommonButton/CommonButton';

class AddArticleCategory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      category_type: props.categoryKey,
      category_name: '',
      image: '',
      imageName: '',
      clicked: false,
    }
  }

  onChangeInput = (e) => {
    if(!e.target.value || e.target.value.trim() == ''){
      this.setState({
        isDisabled: true,
        clicked: true,
      });
    }
    else{
      this.setState({
        isDisabled: false,
        clicked: false,
      });
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChangeImage = (e) => {
    let file = e.target.files[0];
    if(checkImage(e.target.files)) {
      if((file?.size/1000000) <= 20){
        let reader = new FileReader();
        reader.readAsDataURL(file);
        const array = document.getElementById(this.props.categoryKey+"file").value.split("\\");
        reader.onloadend = () => {
          this.setState({
            image: reader.result,
            imageName: array[array.length - 1],
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                image: baseImage,
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById(this.props.categoryKey+"file").value = '';
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById(this.props.categoryKey+"file").value = '';
    }

  };

  removePhoto = (e) => {
    e.preventDefault();
    document.getElementById(this.props.categoryKey+"file").value = '';
    this.setState({
      image: '',
      imageName: '',
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { createCategory, history, categoryKey, isAdminRoute } = this.props;
    const { image, category_name } = this.state;
    let data = {
      image: image,
      category_name: category_name,
      category_type: categoryKey
    };
    createCategory(data, history, isAdminRoute);
    this.setState({
      isDisabled: false,
      clicked: false,
    });
  };
  componentWillUnmount() {
    this.setState({
      category_name: '',
      image: '',
      imageName: '',
      clicked: false,
    })
  }

  onSubmitHandler(e) {
    this.setState({
      clicked: true,
    })
    this.onSubmit(e); 
  }

  render() {
    let isDisabled = false;
    const { imageName, image, category_name, clicked } = this.state;
    const {t} = this.props;
    if(imageName === '' || image === '' || category_name === '' || clicked ) {
      isDisabled = true;
    }
    else{
      isDisabled = false;
    }
    const  addCategory = {
      "fields": [
        {
          "name": "category_name",
          "type": "text",
          "label": t("Category Title"),
          "placeholder": t("Title")
        },
        {
          "name": "image",
          "type": "file",
          "label": t("Category Image"),
          "placeholder": t("Category Photo")
        }
      ],
      "button": t("Create Category"),
      "mandatoryFields": ["title", "image"]
    }
    return (
      <Layout key={this.props.categoryKey}>

        <PageTitle>{t('Create Category')}</PageTitle>
        <FormContainer>
          <form onSubmit={(e) =>this.onSubmitHandler(e)} id={this.props.categoryKey+"form"}>
            {
              addCategory.fields.map((field) => {
                if(field.type === 'file') {
                  return (
                    <ImageContainer key={field.name}>
                      <FieldTitle>{t(field.label)}</FieldTitle>
                      <div>
                        {image &&
                        <div>
                          <img src={image}/>
                          <ImageContentContainer>
                            <div>{imageName}</div>
                            <RemovePhotoButton type="button" color="#aaaaaa" onClick={(e) => this.removePhoto(e)}>{t("Remove photo")}</RemovePhotoButton>
                          </ImageContentContainer>
                        </div>
                        }
                        <ImageInput htmlFor={this.props.categoryKey+"file"} showAtBottom={imageName !== ''}>
                          {image ? t('browse new') : t('browse')}
                          <input
                            id={this.props.categoryKey+"file"}
                            type="file"
                            name="user"
                            accept=".jpeg, .png, .jpg"
                            multiple={false}
                            onChange={(e) => this.onChangeImage(e)}
                            onClick={(e) => e.target.files[0] && this.onChangeImage(e)}
                          />
                        </ImageInput>
                      </div>
                    </ImageContainer>
                  )
                }
                return (
                  <TitleContainer key={field.name}>
                    <FieldTitle>{t(field.label)}</FieldTitle>
                    <StyledInput
                      type={field.type}
                      name={field.name}
                      placeholder={field.placeholder}
                      onChange={this.onChangeInput}
                      value={this.state[field.name]}
                    />
                  </TitleContainer>
                )
              })
            }
            <ImageContainer>
              {/* <SaveButton type="submit" addMargin disabled={isDisabled}>{t('Create Category')}</SaveButton> */}
              <CommonButton
                btnType={"rounded"}
                type="submit"
                addMargin
                disabled={isDisabled}
                styles={{width:'158px', height:'35px', marginBottom:'55px'}}
                title={t('Create Category')}
              />
            </ImageContainer>
          </form>
        </FormContainer>
      </Layout>
    )
  }
}

AddArticleCategory.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  adventureCategories: PropTypes.array.isRequired,
  categoryKey: PropTypes.string,
  createCategory: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func,
  isAdminRoute: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isLoading: state.education.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  createCategory: (data, history, isAdminRoute) => dispatch(createJourneyCategory(data, history, isAdminRoute))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddArticleCategory)));
